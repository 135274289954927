dmx.Component('date-picker', {

    extends: 'input',

    attributes: {
        name: {
            type: String,
            default: ''
        },

        showdropdowns: {
            type: Boolean,
            default: false
        },

        minyear: {
            type: Number,
            default: null
        },

        maxyear: {
            type: Number,
            default: null
        },

        opens: {
            type: String,
            default: 'right' // left, right, center
        },

        dropsup: {
            type: Boolean,
            default: false
        },

        showweeknumbers: {
            type: Boolean,
            default: false
        },

        mindate: {
            type: String,
            default: ''
        },

        maxdate: {
            type: String,
            default: ''
        },

        format: {
            type: String,
            default: null
        },

        invaliddates: {
            type: Array,
            default: []
        },

        'invaliddates-start': {
            type: String,
            default: 'start'
        },

        'invaliddates-end': {
            type: String,
            default: 'end'
        },

        customdates: {
            type: Array,
            default: []
        },

        'customdates-start': {
            type: String,
            default: 'start'
        },

        'customdates-end': {
            type: String,
            default: 'end'
        },

        'customdates-class': {
            type: String,
            default: 'class'
        },

        disableweekends: {
            type: Boolean,
            default: false
        },

        direction: {
            type: String,
            default: 'ltr'
        },

        weeklabel: {
            type: String,
            default: 'W'
        },

        applylabel: {
            type: String,
            default: 'Apply'
        },

        cancellabel: {
            type: String,
            default: 'Cancel'
        },

        timepicker: {
            type: Boolean,
            default: false
        },

        use24hours: {
            type: Boolean,
            default: false
        },

        'minutes-increment': {
            type: Number,
            default: 1
        },

        utc: {
            type: Boolean,
            default: false
        }
    },

    events: {
        show: Event,
        hide: Event,
        apply: Event,
        cancel: Event
    },

    render: function(node) {
        dmx.Component('form-element').prototype.render.call(this, node);

        this.createHiddenInput();

        this.$node.removeAttribute('name');
        this.$node.autocomplete = 'off';

        this.update({});
    },

    createHiddenInput: function() {
        this.input = document.createElement('input');
        if (this.$node.name) this.input.name = this.$node.name;
        this.input.value = this.$node.value;
        this.input.type = 'hidden';

        this.$node.parentNode.insertBefore(this.input, this.$node);
    },

    update: function(props) {
        if (this.props.name) {
            this.input.name = this.props.name;
        }

        if (JSON.stringify(props) != JSON.stringify(this.props)) {
            if (!this.props.format) {
                this.props.format = this.props.timepicker ? 'L LT' : 'L';
            }
    
            $(this.$node).daterangepicker({
                singleDatePicker: true,
                autoUpdateInput: false,
                showWeekNumbers: !!this.props.showweeknumbers,
                showDropdowns: !!this.props.showdropdowns,
                minYear: this.props.minyear || undefined,
                maxYear: this.props.maxyear || undefined,
                opens: this.props.opens,
                drops: !!this.props.dropsup ? 'up' : 'down',
                minDate: this.formatDate(this.props.mindate),
                maxDate: this.formatDate(this.props.maxdate),
                locale: {
                    format: this.props.format,
                    direction: this.props.direction,
                    weekLabel: this.props.weeklabel,
                    applyLabel: this.props.applylabel,
                    cancelLabel: this.props.cancellabel
                },
                buttonClasses: '',
                applyButtonClasses: '',
                cancelButtonClasses: '',
                isCustomDate: this.isCustomDate.bind(this),
                isInvalidDate: this.isInvalidDate.bind(this),
                timePicker: this.props.timepicker,
                timePicker24Hour: this.props.use24hours,
                timePickerIncrement: this.props['minutes-increment']
            }, this.updateValue.bind(this));

            $(this.$node).on('change.daterangepicker', this.onChange.bind(this));
            $(this.$node).on('apply.daterangepicker', this.onApply.bind(this));
    
            $(this.$node).on('show.daterangepicker', this.dispatchEvent.bind(this, 'show'));
            $(this.$node).on('hide.daterangepicker', this.dispatchEvent.bind(this, 'hide'));
            $(this.$node).on('apply.daterangepicker', this.dispatchEvent.bind(this, 'apply'));
            $(this.$node).on('cancel.daterangepicker', this.dispatchEvent.bind(this, 'cancel'));

            this.daterangepicker = $(this.$node).data('daterangepicker');

            if (props.value !== this.props.value) {
                var value = this.props.value;
                if (value == 'now' || value == 'today') {
                    value = this.props.utc ? moment().toISOString() : moment().format('YYYY-MM-DD HH:mm:ss');
                }
                this.$node.defaultValue = this.formatDate(value) || '';
                this.input.defaultValue = value || '';
                this.setValue(value);
            }

            if (props.disabled != this.props.disabled) {
                this.$node.disabled = this.props.disabled;
                this.input.disabled = this.props.disabled;
            }
        }

        this.updateData();
    },

    updateData: function(event) {
        if (event) {
            dmx.validate(this.$node);
        }

        if (this.input.value !== this.data.value) {
            dmx.nextTick(function() {
                this.dispatchEvent('updated');
            }, this);
        }

        this.set('value', this.input.value);
        this.set('disabled', this.$node.disabled);

        if (this.$node.dirty) {
            this.set('invalid', !this.$node.validity.valid);
            this.set('validationMessage', this.$node.validationMessage);
        }
    },

    formatDate: function(str) {
        if (!str) return undefined;
        if (str == 'now' || str == 'today') return moment().format(this.props.format);
        var m = moment(str);
        return m.isValid() ? m.format(this.props.format) : undefined;
    },

    isInvalidDate: function(date) {
        if (this.props.disableweekends) {
            var day = date.day();
            if (day === 0 || day === 6) return true;
        }

        return this.props.invaliddates.some(function(range) {
            return this.isInRange(date, range, this.props['invaliddates-start'], this.props['invaliddates-end']);
        }, this);
    },

    isCustomDate: function(date) {
        return this.props.customdates.filter(function(range) {
            return this.isInRange(date, range, this.props['customdates-start'], this.props['customdates-end']);
        }, this).map(function(range) {
            return range[this.props['customdates-class']];
        }, this);
    },

    isInRange: function(date, range, start, end) {
        if (range[start] && range[end]) {
            return date.isSameOrAfter(range[start]) && date.isSameOrBefore(range[end]);
        }

        if (range[start]) {
            return date.isSameOrAfter(range[start]);
        }

        if (range[end]) {
            return date.isSameOrBefore(range[end]);
        }

        return false;
    },

    onChange: function(event) {
        if (!moment(this.$node.value, this.props.format).isValid()) {
            this.setValue('');
        }
    },

    onApply: function(event) {
        var oldValue = this.data.value;
        this.updateValue(this.daterangepicker.startDate);
        if (this.input.value !== oldValue) {
            dmx.nextTick(function() {
                this.dispatchEvent('changed');
            }, this);
        }
    },

    updateValue: function(date) {
        this.setValue(this.props.utc ? date.toISOString() : date.format('YYYY-MM-DD HH:mm:ss'));
    },

    setValue: function(value) {
        if (value) {
            this.daterangepicker.setStartDate(this.formatDate(value));
            this.daterangepicker.setEndDate(this.formatDate(value));
        }
        this.$node.value = this.formatDate(value) || '';
        this.input.value = value || '';
        this.updateData(true);
    },

    format: function(date) {
        if (!date) return '';
        return moment(date, 'YYYY-MM-DD HH:mm:ss').format(this.props.format);
    },

    destroy: function() {
        this.input.off('.daterangepicker');
    }

});
